html, body {
  margin: 0;
  width: 100%;
    font-family: Roboto, serif;
}

.header-container {
  text-align: left;
  background: linear-gradient(90deg, #004d9f, #1bffff);
  color: #fff;
  padding: 1rem 0 1rem  5rem;

}

.header-text {
  font-size: 40px;
  text-align: left;
}

.header-nav-icons {
  text-align: right;
  margin: 0 5rem 0 0;
}

.card-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon {
    color: #fff;
}

.logo-width {
    max-width: 164px;
    max-height: 50px;

}

.box {
    width: 24px;
    height: 24px;
    margin: 1rem;
    border: 1px solid black;
}

.colorContainer {
    display: flex
}

.form-buttons {
    margin-top: 2rem;
}

.page-not-found-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.page-not-found-img {
    margin-top: 3rem;
    height: 600px;
}
